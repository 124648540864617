import findAnchorTags from "./find-anchor-tags";
import convertLinks from "./convert-links";
import initialisePushState from "./initialise-push-state";
// import initialiseObserver from "./initialise-observer";
import log from "./log";

const BETA_HOSTNAMES = ["kutoku-publisher.test"];
const HOSTNAME = window.location.hostname;

function initialise() {
  log("Initializing...");

  const anchorTags = findAnchorTags();
  convertLinks(anchorTags);

  // Initialize features only for approved domains
  if (BETA_HOSTNAMES.includes(HOSTNAME)) {
    log(`Initialising pushState for ${HOSTNAME}`);
    initialisePushState();
  } else {
    log(`Skipping initialisePushState because ${HOSTNAME} is not an approved hostname`);
  }
}

export default initialise;
