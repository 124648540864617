import log from "./log";
import findAnchorTags from "./find-anchor-tags";
import convertLinks from "./convert-links";
import getPath from "./get-path";

// Provide support for SPAs that use the history APIs to transition between pages
// This overrides the pushState function
// https://stackoverflow.com/questions/4570093/how-to-get-notified-about-changes-of-the-history-via-history-pushstate
// https://github.com/googleanalytics/autotrack/blob/74a4b5b62e1c2fd4eb2e77cdef3829e449aafe38/lib/plugins/url-change-tracker.js

function initialiseHistory() {
  // Feature detection to prevent errors in unsupporting browsers.
  if (!history.pushState) return;

  (function (history) {
    let pushState = history.pushState;
    let path = getPath();

    history.pushState = function () {
      pushState.apply(history, arguments);

      // Call the update logic asychronously to help ensure that app logic
      // responding to the URL change happens prior to this.
      setTimeout(() => {
        const oldPath = path;
        const newPath = getPath();

        // Check to see if the path has changed
        if (oldPath !== newPath) {
          path = newPath;
          log(`Detected pushState change: ${oldPath} => ${newPath}`);

          // Run all the new page's links through the autolinker
          const anchorTags = findAnchorTags();
          convertLinks(anchorTags);
        }
      }, 0);
    };
  })(window.history);
}

export default initialiseHistory;
